@import './base.less';
@import './layout.less';
@import './form.less';
@import './light.less';
@import './dark.less';

.activeRoute {
  background-color: @w1;
  color: @w;
  font-weight: 700;
}

.ant-dropdown .ant-dropdown-menu {
  padding: 10px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  padding: 0;
  .ant-dropdown-menu-title-content {
    a {
      display: block;
      padding: 5px 10px;
      border-radius: 5px;
      color:@b6;
      &.activeSubRoute {
        background-color: rgba(27, 233, 233,0.1);
        color: @b;
        font-weight: 700;
      }
    }
  }
}

.showdiagram {
  position: relative;
  width: 1000px;
  height: 800px;
  // background-image: url(./assets/imgs/ddbg.png);
  background-color: @b3;
  z-index: 1;
  .ir {
    width: 100%;
    height: 100%;
    border: 1px dashed rgba(78, 201, 231, 0.5);
    z-index: 2;
  }
}


.diagram-component{
  width: 1000px;
  height:500px;
  background-color: aqua;
  position: relative;
  z-index: 1;
}

.palette-component{
  width: 1000px;
  height:200px;
  position: relative;
  z-index: 2;
}

.contextMenu{
  position: absolute;
  display: none;
  z-index: 100;
}

.ant-modal .ant-modal-content {
  background-color: #001539;
  color: @w;
  .ant-modal-header {
    background-color: transparent;
    border-bottom: 1px solid @w1;
    padding: 0 0.5rem 0.5rem 0.5rem;
  }
  .ant-modal-title {
    background-color: #001539;
    color: aqua;
  }
  .ant-modal-close {
    color: @w3;
  }
  .curTabs,
  .curTabs2 {
    .ant-tabs-top > .ant-tabs-nav::before {
      border-color: #002844;
    }

    .ant-tabs-nav .ant-tabs-tab {
      border-color: #002844;
      color: @w6;
    }
    .ant-tabs-top > .ant-tabs-nav {
      margin-bottom: 0;
    }
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
      // border-color: @w1;
      background: linear-gradient(#003455, #0079b9);
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #86e7ff;
    }
  }
  .curTabs2 {
    .ant-tabs-nav .ant-tabs-tab {
      padding: 5px;
    }
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
      // border-color: @w1;
      background: linear-gradient(#887400, #b9a600);
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: @w;
    }
  }
}
