.icon {
  width: 16px;
  height: 16px;
  display: block;
  background-image: url(../assets/icons/icon_tools.svg);
  background-repeat: no-repeat;
  &.imgType {
    background-position: -260px 0;
    &.off {
      background-position: -260px -20px;
    }
  }
  &.tableType {
    background-position: -280px 0;
    &.off {
      background-position: -280px -20px;
    }
  }
  &.ok {
    background-position: -200px 0;
    &.off {
      background-position: -200px -20px;
    }
  }
  &.cancel {
    background-position: -180px 0;
    &.off {
      background-position: -180px -20px;
    }
  }
  &.edit {
    background-position: -240px 0;
    &.off {
      background-position: -240px -20px;
    }
  }
  &.view {
    background-position: -220px 0;
    &.off {
      background-position: -220px -20px;
    }
  }
  &.close {
    background-position: -180px 0;
    &.off {
      background-position: -180px -20px;
    }
  }
  &.newFlow {
    background-position: -80px 0;
    &.off {
      background-position: -80px -20px;
    }
  }
  &.save {
    background-position: -120px 0;
    &.off {
      background-position: -120px -20px;
    }
  }
  &.import {
    background-position: -160px 0;
    &.off {
      background-position: -160px -20px;
    }
  }
  &.mod3 {
    background-position: 0px 0;
    &.off {
      background-position: 0px -20px;
    }
  }
  &.mod_left {
    background-position: -20px 0;
    &.off {
      background-position: -20px -20px;
    }
  }
  &.mod_bottom {
    background-position: -40px 0;
    &.off {
      background-position: -40px -20px;
    }
  }
  &.mod_full {
    background-position: -60px 0;
    &.off {
      background-position: -60px -20px;
    }
  }
}

.icon_close_small {
  background-image: url(../assets/icons/close.svg);
  background-position: center;
  background-size: 1rem;
  background-repeat: no-repeat;
  height: 1rem;
  width: 1rem;
  cursor: pointer;
}

.formSepLine {
  margin: 1rem 0;
  height: 1px;
  background-color: @b;
  box-shadow: 0 1px 0 @w1;
}

.form_EleList {
  display: flex;
  flex-wrap: wrap;
  li {
    width: calc(100% / 4);

    height: 50px;
    text-align: center;
    img {
      max-height: 100%;
    }
  }
}

.formLine {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  .form_settingBtn {
    border: 1px solid @w2;
    font-size: 12px;
    padding: 0.2rem 1rem;
    border-radius: 3px;
    cursor: pointer;
  }
  .formEle_chebox {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    label {
      display: flex;
      align-items: center;
      color: @w;
      span {
        margin-left: 1rem;
      }
    }
  }
  .formLabel {
    width: calc(100% - 12rem);
    text-align: right;
    color: @w8;
  }
  .formEle {
    width: 8rem;
    input[type='text'] {
      background-color: @b;
      border: 1px solid @w3;
      color: aquamarine;
      padding: 0.1rem 0.5rem;
      width: 100%;
      text-align: right;
    }
  }
  .formUnit {
    margin-left: 0.5rem;
    font-size: 12px;
    width: 4rem;
    // background-color: red;
  }
}

.inputN {
  background-color: @b;
  border: 1px solid @w3;
  color: aquamarine;
  padding: 0.8rem;
  &:focus,
  &:hover {
    border: 1px solid rgba(32, 100, 247, 1);
  }
  // width: 100%;
}

.icon_arrow_w {
  background-position: center;

  background-repeat: no-repeat;
  height: 1rem;
  width: 1rem;
  cursor: pointer;
  &.down {
    background-image: url(../assets/icons/arrow_down_w.svg);
    background-size: 1rem;
  }
  &.right {
    background-image: url(../assets/icons/arrow_right_w.svg);
    background-size: 0.5rem;
  }
}

select {
  &.nor {
    font-size: 1.4rem;
    background: linear-gradient(0deg, rgb(49, 107, 158) 0%, rgba(31, 65, 95, 1) 100%);
    color: @w;
    padding: 0.5rem 0.8rem 0.5rem 0.8rem;
    border-radius: 5px;
    border: 1px solid @b;
    text-shadow: 0 1px 0 @b;
    box-shadow: inset 1px 0 0 @w2, inset -1px 0 0 @w1, inset 0 1px 3px @w2, 0 0 5px @b5;
    &::-webkit-select-dropdown {
      background-color: red;
    }
    option {
      border-radius: 0;
      background-color: #282a2c;
      color: aqua;
      font-size: 1.4rem;
      
      &[disabled] {
        color: @b4;
        text-shadow: 0 1px 0 @w;
        background-color: @b3;
      }
    }
  }
}

.button {
  box-shadow: inset 1px 0 0 @w2, inset -1px 0 0 @w1, inset 0 1px 3px @w2, 0 0 5px @b5;
  border-radius: 5px;
  border: 1px solid @b;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  background: linear-gradient(0deg, rgb(0, 116, 183) 0%, rgb(1, 49, 107) 100%);
  text-shadow: 0 1px 0 @b;
  &:hover {
    background: linear-gradient(0deg, rgb(1, 49, 107) 0%, rgb(0, 116, 183) 100%);
  }
  &.small{
    color: @w8;
    padding: 0.5rem;
  }
  &.new {
    font-size: 1.8rem;
    padding: 0.8rem 1.5rem;
    color: @w8;
  }
  &.new_middle {
    font-size: 1.6rem;
    padding: 0.5rem 1.5rem;
    color: @w8;
  }
  &.edit {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    color: @w8;
    background: linear-gradient(0deg, rgb(56, 154, 239) 0%, rgb(10, 10, 10) 100%);
  }
  &.edit_small {
    font-size: 1.2rem;
    padding: 0.2rem 0.5rem;
    border-radius: 3px;
    color: @w8;
    background: linear-gradient(0deg, rgb(56, 154, 239) 0%, rgb(10, 10, 10) 100%);
    box-shadow: inset 1px 0 0 @w2, inset -1px 0 0 @w1, inset 0 1px 3px @w2, 0 0 2px @b3;
  }
  &.delete_small {
    font-size: 1.2rem;
    padding: 0.2rem 0.5rem;
    border-radius: 3px;
    color: @w8;
    background: linear-gradient(0deg, rgb(239, 56, 56) 0%, rgb(10, 10, 10) 100%);
    box-shadow: inset 1px 0 0 @w2, inset -1px 0 0 @w1, inset 0 1px 3px @w2, 0 0 2px @b3;
  }
  &.cancle {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    color: @w8;
    background: linear-gradient(0deg, rgb(164, 164, 164) 0%, rgb(10, 10, 10) 100%);
  }
  &.cancle_small {
    font-size: 1.2rem;
    padding: 0.2rem 0.5rem;
    color: @w8;
    border-radius: 3px;
    background: linear-gradient(0deg, rgb(164, 164, 164) 0%, rgb(10, 10, 10) 100%);
    box-shadow: inset 1px 0 0 @w2, inset -1px 0 0 @w1, inset 0 1px 3px @w2, 0 0 2px @b3;
  }
  &.cancle_large {
    font-size: 1.8rem;
    padding: 0.8rem 1.5rem;
    color: @w8;
    background: linear-gradient(0deg, rgb(164, 164, 164) 0%, rgb(37, 37, 37) 100%);
    &:hover {
      background: linear-gradient(0deg, rgb(37, 37, 37) 0%, rgb(164, 164, 164) 100%);
    }
  }
  &.db {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    color: @w8;
    display: inline-block;
    box-shadow: inset 1px 0 0 @w2, inset -1px 0 0 @w1, inset 0 1px 3px @w2, 0 0 3px @b;
    background: linear-gradient(0deg, rgb(0, 116, 183) 0%, rgb(1, 49, 107) 100%);
  }
  &.db_small {
    font-size: 1.2rem;
    padding: 0.3rem 1rem;
    color: @w8;
    display: inline-block;
    box-shadow: inset 1px 0 0 @w2, inset -1px 0 0 @w1, inset 0 1px 3px @w2, 0 0 3px @b;
    background: linear-gradient(0deg, rgb(0, 116, 183) 0%, rgb(1, 49, 107) 100%);
  }
  &.db_small_gray {
    font-size: 1.2rem;
    padding: 0.3rem 1rem;
    color: @w8;
    display: inline-block;
    box-shadow: inset 1px 0 0 @w2, inset -1px 0 0 @w1, inset 0 1px 3px @w2, 0 0 3px @b;
    background: linear-gradient(0deg, rgb(115, 115, 115) 0%, rgb(70, 70, 70) 100%);
  }
}

input{
  
  &.noborder{
    background-color: transparent;
    border: 1px solid @w1;
    font-size: 1.6rem;
    color: aqua;
    text-align: center;
    padding: 0.3rem;
  }

  &.normal{
    background-color: transparent;
    border: 1px solid @w1;
    font-size: 1.2rem;
    color: aqua;
    // text-align: center;
    padding: 0.2rem 0.5rem;
  }

  &.w80{
    width: 80px;
  }

  
  &.nameInput{
    background-color: @b8;
    border: 1px solid @w1;
    font-size: 1.2rem;
    color: aqua;
    // text-align: center;
    padding: 0.2rem 0.5rem;
  }
  &.nameInput2{
    background-color: @b5;
    border: 1px solid @w1;
    font-size: 1.6rem;
    color: aqua;
    // text-align: center;
    padding: 0.5rem 0.5rem;
  }
  &:focus{
    border: 1px solid #1395f1;
    box-shadow: 0 0 3px #1395f1; 
  }
}

textarea{
  &.nameInput2{
    background-color: @b5;
    border: 1px solid @w1;
    font-size: 1.6rem;
    color: aqua;
    // text-align: center;
    padding: 0.5rem 0.5rem;
  }
  &:focus{
    border: 1px solid #1395f1;
    box-shadow: 0 0 3px #76b3ef; 
  }
}

