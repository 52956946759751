@import "./var.less";
.dark {
  color: @w6;
  background-color: #242629;
  .activeRoute {
    background-color: @w1;
    color: @w;
  }
  .unit,
  .unit2 {
    color: @w3;
  }

  .aqua{
    color:aqua
  }

  .pageContent {
    background: radial-gradient(circle, rgba(230, 229, 241, 0.15) 0%, rgba(1, 18, 22, 0) 100%);
    border-top: 2px solid @b;
    box-shadow: inset 0 1px 0 @w2;
  }
  .pageTopBar{
    background-color: #242629;
  }
  .ant-select .ant-select-arrow{
    color: @w6;
  }
}
