.react-flow__edge-path{
  &.customEdge{
    stroke: aqua;
  }
}

.react-flow__edge.selected .react-flow__edge-path,
  .react-flow__edge.selectable:focus .react-flow__edge-path,
  .react-flow__edge.selectable:focus-visible .react-flow__edge-path {
    &.customEdge{
      stroke: red;
      
    }
  }

