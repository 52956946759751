@pageTopHeight: 6rem;

html,
body,
#root {
  width: 100%;
  height: 100%;
  font-size: 66.7%;
  .ant-btn-primary {
    background:linear-gradient(0deg, rgb(0, 116, 183) 0%, rgb(1, 49, 107) 100%);
  }

  .ant-card-type-inner .ant-card-head {
    background-color: @b05;
  }
}


.t1 {
  font-size: 25px;
  font-weight: bold;
}
.t2 {
  font-size: 22px;
  font-weight: bold;
}
.t3 {
  font-size: 18px;
  font-weight: bold;
}
.t4 {
  font-size: 16px;
  font-weight: bold;
}
.t5 {
  font-size: 14px;
  font-weight: bold;
}
.nbt1 {
  font-size: 25px;
}
.nbt2 {
  font-size: 22px;
}
.nbt3 {
  font-size: 18px;
}
.nbt4 {
  font-size: 16px;
}

.unitT{
  font-size: 12px;
  font-weight: normal;
}
.unit {
  font-size: 12px;
  font-weight: normal;
  margin-left: 3px;
  color: red;
}
.units {
  font-size: 12px;
  font-weight: normal;
  // margin-left: 3px;
  color: @w6;
}

.aqua{
  color:aqua
}
.white{
  color:@w;
}

.light,
.dark {
  height: 100%;
}

.mt1 {
  margin-top: 1rem;
}
.mt05 {
  margin-top: 0.5rem;
}


.mt2 {
  margin-top: 2rem;
}

.mt3 {
  margin-top: 3rem;
}

.mt4 {
  margin-top: 4rem;
}
.ml1 {
  margin-left: 1rem;
}
.mr1 {
  margin-right: 1rem;
}

.w {
  color: @w;
}

.b {
  color: @b;
}

.aqua {
  color: aqua;
}
.noResults {
  height: 60rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid @w1;
  box-shadow: inset 0 -1px 0 @b, inset 1px 0 0 @b, 0 -1px 0 @b, 1px 0 0 @b, 0 0 10px @b2;
}

.username {
  font-size: 1.6rem;
  height: 100%;
  // background-color: red;
  padding-right: 1rem;
  border-right: 1px solid @w2;
  box-shadow: 1px 0 0 @b;
}

.cus_scroll {
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b0b0b0;
  }

  /* 滚动条滑块 */
  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    // background-color: #f7b859;
    background-image: url(../assets/imgs/css/scrollbg.png);
  }

  &::-webkit-scrollbar-track {
    background-color: @b;
    border: 1px solid @w3;
  }
  /* Buttons */
  &::-webkit-scrollbar-button:single-button {
    background-color: fade(#a78860, 80);
    height: 15px;
    width: 10px;
  }
  // /* Up */
  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-width: 0 8px 8px 8px;
    background-repeat: no-repeat;
    background-image: url(../assets/imgs/css/scroll_arrow_up.svg);
    background-size: 60%;
    background-position: center;
    // border-color: transparent transparent #555555 transparent;
  }

  &::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    border-color: transparent transparent #777777 transparent;
  }
  /* Down */
  &::-webkit-scrollbar-button:single-button:vertical:increment {
    border-width: 8px 8px 0 8px;
    border-color: #555555 transparent transparent transparent;
    background-image: url(../assets/imgs/css/scroll_arrow_down.svg);
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
  }

  &::-webkit-scrollbar-button:vertical:single-button:increment:hover {
    border-color: #777777 transparent transparent transparent;
  }
}

.cus_scroll2 {
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b0b0b0;
  }

  /* 滚动条滑块 */
  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-image: url(../assets/imgs/css/scrollbg2.png);
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background-color: @b;
    border: 1px solid @w3;
  }
  /* Buttons */
  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-width: 0 8px 8px 8px;
    background-repeat: no-repeat;
    background-image: url(../assets/imgs/css/scroll_arrow_up.svg);
    background-size: 60%;
    background-position: center;
  }

  &::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    border-color: transparent transparent #777777 transparent;
  }
  /* Down */
  &::-webkit-scrollbar-button:single-button:vertical:increment {
    border-width: 8px 8px 0 8px;
    border-color: #555555 transparent transparent transparent;
    background-image: url(../assets/imgs/css/scroll_arrow_down.svg);
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
  }

  &::-webkit-scrollbar-button:vertical:single-button:increment:hover {
    border-color: #777777 transparent transparent transparent;
  }
}

.routes {
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  li {
    a {
      padding: 0.8rem 2rem;
      margin-right: 1rem;
      border-radius: 5px;
      color: @w5;
      font-size: 1.6rem;
      &:hover {
        background-color: @w1;
      }
    }
    &.webTitle {
      a {
        font-size: 2.2rem;
        color: #a7f7dd;
        padding: 0 4rem 0 0;
        font-weight: 700;
        &:hover {
          background-color: transparent;
        }
      }
    }
    &.hasChild {
      background-image: url(../assets/icons/arrow_down.svg);
      background-repeat: no-repeat;
      background-position: calc(100% - 25px) center;
      background-size: 10px;
      a {
        padding-right: 4rem;
      }
    }
  }
}

.pageContent {
  height: calc(100% - @pageTopHeight);
  overflow: hidden;
}

.pageTopBar {
  height: @pageTopHeight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .pageTopRight {
    display: flex;
    align-items: center;
  }
}

.topSearchBar {
  display: flex;
  box-shadow: 0 0 10px @b;
  .topSearch {
    padding: 0.8rem;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border: 1px solid @b;
    box-shadow: inset -1px 0 0 @w1, inset 1px 0 0 @w1, inset 0 1px 0 @w1, inset 0 -1px 0 @w1;
    font-size: 1.4rem;
    background: linear-gradient(180deg, rgba(55, 54, 49, 1) 0%, rgb(21, 21, 21) 56%);
    // border-right: none;
    color: @w;
    &:focus {
      box-shadow: inset -1px 0 0 rgba(1, 250, 250, 0.3), inset 1px 0 0 rgba(1, 250, 250, 0.3),
        inset 0 1px 0 rgba(1, 250, 250, 0.3), inset 0 -1px 0 rgba(1, 250, 250, 0.3);
    }
  }
  .topSubmit {
    padding: 0.8rem;
    border: 1px solid @b;
    color: @w;
    border-left: none;
    border-top-right-radius: 0.5rem;
    box-shadow: inset -1px 0 0 @w2, inset 0 1px 0 @w2;
    border-bottom-right-radius: 0.5rem;
    background: linear-gradient(180deg, rgba(150, 121, 5, 1) 0%, rgb(21, 21, 21) 56%);
  }
}
.userIcon {
  padding: 0.8rem 1.2rem;
  border: 1px solid @b;
  color: @w;
  border-radius: 0.5rem;
  box-shadow: inset -1px 0 0 @w2, inset 0 1px 0 @w2, inset 1px 0 0 @w2, inset 0 -1px 0 @w1;
  background: linear-gradient(180deg, rgba(150, 121, 5, 1) 0%, rgba(21, 21, 21) 50%);
}

.userPanel {
  margin-right: 1.5rem;
  cursor: pointer;
  box-shadow: 0 0 1rem @b;
}
.loginTitle2 {
  font-size: 1.6rem;
  color: @w;
  text-align: center;
  margin-bottom: 2rem;
}
.loginPage {
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #2f3540;
  align-items: center;
  .loginTitle {
    height: 12rem;
    display: flex;
    align-items: center;
    margin-left: 5rem;
    font-size: 3.5rem;
    color: @b;
    position: absolute;
    z-index: 1;
  }
  // .loginImg {
  //   height: calc(100% - 12rem);
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
  .loginLeft {
    height: 100%;
    width: calc(100% - 23%);
    position: relative;
    z-index: 0;
    background-image: url(../assets/imgs/loginbg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // background-attachment:;
    // text-align: center;
  }
  .loginRight {
    border-left: 1px solid @w1;
    width: 23%;
    height: 100%;
    // background-color: @b4;
    padding: 0 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logoPic {
    text-align: center;
    // margin-bottom: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logoPic {
    text-align: center;
    // margin-bottom: 4rem;
    // padding-left: 2rem;
  }
}

.loginContainer{
  height: 100%;
  width: 100%;
}

.loginformArea{
  position: absolute;
  z-index:10;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  .ant-input{
    background-color: red;
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #060D0F inset; /* 设置背景颜色 */
      -webkit-text-fill-color: @w; /* 设置文本颜色 */
    }
  }
  /* 当输入框聚焦时的自动填充样式 */
  .ant-input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px red inset;
    -webkit-text-fill-color: @b;
  }
}
.loginform{
  padding: 5rem 20rem;
  background-color: @w05;
  border:1px solid @w1;
  border-radius: 15px;
  margin-top: -8rem;
}

#whol,#whol2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index:0;
}

.ant-card:not(.ant-card-bordered) {
  box-shadow: none;
}

.loadingArea {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
// .ant-dropdown .ant-dropdown-menu{
//   // background-color: red;
// }
.pageTitle {
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pageTopExtra {
    display: flex;
    // justify-content: space-between;
    align-items: center;
  }

  .listType {
    display: flex;
    box-shadow: 0 0 10px @b;
    border-radius: 5px;
    li {
      padding: 1rem;
      border: 1px solid @b;
      border-right: none;
      cursor: pointer;
      box-shadow: inset 1px 0 0 @w1, inset -1px 0 0 @w1, inset 0 1px 3px @w2;
      &:first-child {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: inset 0 1px 3px @w2, inset -1px 0 0 @w1;
        border-right: 1px solid @b;
      }
      &.active {
        background: linear-gradient(0deg, rgb(13, 120, 213) 0%, rgb(1, 38, 71) 100%);
      }
    }
  }
}

.showSImg {
  // width: 100%;
  height: 100%;
  border: 1px solid @w1;
  background: linear-gradient(0deg, rgba(32, 32, 32, 1) 0%, rgba(20, 20, 20, 1) 100%);
  border-radius: 1rem;
  padding: 1.5rem;
  overflow: hidden;
  box-shadow: 0 0 15px @b;
  padding: 1rem;
  .showSImg_inner {
    border: 1px solid @w1;
    border-radius: 0.8rem;
    overflow: hidden;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.indexShow {
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .showPimg {
    border: 1px solid @w2;
    background: linear-gradient(0deg, rgba(32, 32, 32, 1) 0%, rgba(20, 20, 20, 1) 100%);
    border-radius: 1rem;
    padding: 1.5rem;
    overflow: hidden;
    box-shadow: 0 0 15px @b;
    height: 85%;
  }
  .simg {
    border: 1px solid @w2;
    border-radius: 0.8rem;
    overflow: hidden;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .page_Content {
    height: calc(100% - 5rem);
    margin-top: 2rem;
    .content_top {
      height: 25rem;
      display: flex;
      .p_img {
        width: 50rem;
        height: 100%;
      }
      .p_descs {
        width: calc(100% - 55rem);
        margin-left: 5rem;
        .p_descs_title {
          font-size: 2rem;
          color: aqua;
        }
        .p_descs_detail_content {
          word-break: break-all;
          height: 14.5rem;
          font-size: 1.6rem;
          padding-right: 1rem;
          text-indent: 2em;
          overflow: hidden;
          .cus_scroll();
          color: @w;
        }
      }

      .p_params {
        display: flex;
        align-items: center;
        height: 5.5rem;
        li {
          margin-right: 3rem;
          text-align: center;
          width: calc((100% - 9rem) / 4);
          border: 1px solid @b;
          height: 100%;
          display: flex;
          background: linear-gradient(0deg, rgb(0, 113, 184) 0%, rgba(20, 20, 20, 1) 100%);
          justify-content: center;
          align-items: center;
          box-shadow: 0 0 10px @b, inset 1px 1px 0 @w2, inset -1px 0px 0 @w2;
          border-radius: 5px;
          p {
            &:first-child {
              font-size: 1.4rem;
            }
            &:last-child {
              font-size: 2rem;
              color: @w;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .content_bottom {
      height: calc(100% - 27rem);
      .flowImgList {
        height: calc(100% - 3rem);
        .cus_scroll();

        // background-color: red;
        display: flex;
        flex-wrap: wrap;
        li {
          width: calc((100% - 21rem) / 4);
          text-align: center;
          // box-shadow: 0 0 10px @b;
          height: 50%;
          margin-right: 7rem;
          border-radius: 5px;
          cursor: pointer;
          .imgInner {
            height: calc(100% - 3.8rem);
            width: 100%;
            padding: 1rem;
            border-radius: 5px;
          }
          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
    }
  }

  .indexShowMain {
    width: 80%;
    height: 90%;
  }

  .index_pageSep {
    height: 5rem;
    // display: flex;
    // padding-top: 1rem;
    // li {
    //   border-radius: 5px;
    //   height: 3rem;
    //   padding: 0.5rem 1rem;
    //   cursor: pointer;
    //   font-size: 1.6rem;
    //   &.active {
    //     background: linear-gradient(0deg, rgba(25, 52, 76, 1) 0%, rgba(31, 65, 95, 1) 100%);
    //     box-shadow: 0 0 5px @b, inset 0 1px 0 @w2, inset 1px 0 0 @w2;
    //     margin-right: 1rem;
    //   }
    // }
  }
  .indexShow_content {
    height: calc(100% - 9rem);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    li {
      width: calc((100% - 16rem) / 3);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8rem;
      height: 46%;
      // background-color: red;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .showProjects {
        width: 100%;
        height: 100%;
      }

      .showPname {
        display: flex;
        margin-top: 1rem;
        justify-content: space-between;
        background-image: url(../assets/icons/dot_icon.png);
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 5rem;
        .sp_left {
          display: flex;
          align-items: center;
          .sp_name {
            font-size: 1.8rem;
            color: aqua;
            margin-right: 1rem;
            cursor: pointer;
          }
        }
        .sp_right {
          width: 100px;
          background: linear-gradient(0deg, rgba(25, 52, 76, 1) 0%, rgba(31, 65, 95, 1) 100%);
          border-radius: 5px;
          box-shadow: 0 0 5px @b, inset 0 1px 0 @w2, inset 1px 0 0 @w2;
          display: flex;
          .sp_icon {
            width: calc(100px / 3);
            border-right: 0.1rem solid @b;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
            &.sp_icon_view {
              background-image: url(../assets/icons/icon_view.png);
            }
            &.sp_icon_edit {
              background-image: url(../assets/icons/icon_edit.png);
            }
            &.sp_icon_delete {
              background-image: url(../assets/icons/icon_delete.png);
            }
            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }
    // background-color: blue;
  }
}

.indexTable {
  max-height: calc(100% - 9rem);
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 0 10px @b;
  .cus_scroll();
  table {
    width: 100%;
    border-top: 1px solid @b;
    border-left: 1px solid @b;
    overflow: hidden;
    border-radius: 5px;
    thead {
      position: sticky;
      border-radius: 5px;
      top: 0;
      // background-color: #fff; /* 设置头部背景色，使其更美观 */
    }

    th {
      font-size: 1.8rem;
      padding: 0.8rem;
      background: linear-gradient(0deg, rgb(0, 93, 174) 0%, rgb(2, 44, 78) 100%);
      box-shadow: inset 1px 0 0 @w2, inset 0 1px 0 @w2;
      border-right: 1px solid @b;
      color: @w;
      font-weight: normal;
      text-shadow: 0 2px 0 @b;
      &:first-child {
        border-top-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
      }
      &.unit{
        
      }
    }
    td {
      text-align: center;
      padding: 1rem;
      border-bottom: 1px solid @b;
      border-right: 1px solid @b;
      background-color: @b3;
      box-shadow: inset -1px 0 0 @w1, inset 1px 0 0 @w1, inset 0 -1px 0 @w1;
      .itemName {
        font-size: 1.6rem;
        color: aqua;
        margin-top: 1rem;
      }
      .itemPic {
        display: flex;
        justify-content: center;
      }
      .itemPicInner {
        border-radius: 5px;
        overflow: hidden;
        padding: 0.3rem;
        border: 1px solid @w2;
        background: linear-gradient(0deg, rgba(32, 32, 32, 1) 0%, rgba(20, 20, 20, 1) 100%);
        box-shadow: 0 0 5px @b;
        img {
          width: 70px;
          border-radius: 5px;
          border: 1px solid @w2;
        }
      }

      .item_action_c {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
      }

      .item_action {
        height: 40px;
        width: 150px;
        background: linear-gradient(0deg, rgba(25, 52, 76, 1) 0%, rgba(31, 65, 95, 1) 100%);
        border-radius: 5px;
        box-shadow: 0 0 5px @b, inset 0 1px 0 @w1, inset 1px 0 0 @w1;
        display: flex;

        .sp_icon {
          width: calc(100% / 3);
          height: 100%;
          border-right: 0.1rem solid @b5;
          background-position: center;
          background-repeat: no-repeat;
          box-shadow: inset -1px 0 0 @w1;
          cursor: pointer;
          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          &.sp_icon_view {
            background-image: url(../assets/icons/icon_view.png);
          }
          &.sp_icon_edit {
            background-image: url(../assets/icons/icon_edit.png);
          }
          &.sp_icon_delete {
            background-image: url(../assets/icons/icon_delete.png);
          }
          &:last-child {
            border-right: none;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }

      &.itemFlowTotal {
        font-size: 2rem;
        // font-weight: bold;
        color: cyan;
        cursor: pointer;
      }
      &.itemTaskTotal {
        font-size: 2rem;
        // font-weight: bold;
        color: rgb(255, 204, 0);
        cursor: pointer;
      }

      &.tabelImg {
        width: 180px;
        cursor: pointer;
      }
      &.itemDesc {
        text-align: left;
        width: 400px;
        font-size: 1.4rem;
      }

      &.item_action_td {
        width: 200px;
      }
    }
  }
}

.indexTable2 {
  // max-height: calc(100% - 9rem);
  // overflow: hidden;
  border-radius: 5px;
  // box-shadow: 0 0 10px @b;
  // .cus_scroll();
  table {
    width: 100%;
    border-top: 1px solid @b;
    border-left: 1px solid @b;
    overflow: hidden;
    border-radius: 5px;
    thead {
      position: sticky;
      border-radius: 5px;
      top: 0;
      // background-color: #fff; /* 设置头部背景色，使其更美观 */
    }

    th {
      font-size: 1.8rem;
      padding: 0.8rem;
      background: linear-gradient(0deg, rgb(0, 93, 174) 0%, rgb(2, 44, 78) 100%);
      box-shadow: inset 1px 0 0 @w2, inset 0 1px 0 @w2;
      border-right: 1px solid @b;
      color: @w;
      font-weight: normal;
      text-shadow: 0 2px 0 @b;
      &:first-child {
        border-top-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
      }
      &.unit{
        
      }
      &.tempUse{
        background: #005CAD;
        border-radius: 0;
        box-shadow: inset 1px 0 0 @w2, inset 0 1px 0 @b,inset 0 2px 0 @w2;;
      }
    }
    td {
      text-align: center;
      padding: 1rem;
      border-bottom: 1px solid @b;
      border-right: 1px solid @b;
      background-color: @b3;
      font-size: 1.4rem;
      box-shadow: inset -1px 0 0 @w1, inset 1px 0 0 @w1, inset 0 -1px 0 @w1;
      
      .itemName {
        font-size: 1.6rem;
        color: aqua;
        margin-top: 1rem;
      }
      .itemPic {
        display: flex;
        justify-content: center;
      }
      .itemPicInner {
        border-radius: 5px;
        overflow: hidden;
        padding: 0.3rem;
        border: 1px solid @w2;
        background: linear-gradient(0deg, rgba(32, 32, 32, 1) 0%, rgba(20, 20, 20, 1) 100%);
        box-shadow: 0 0 5px @b;
        img {
          width: 70px;
          border-radius: 5px;
          border: 1px solid @w2;
        }
      }

      .item_action_c {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
      }

      .item_action {
        height: 40px;
        width: 150px;
        background: linear-gradient(0deg, rgba(25, 52, 76, 1) 0%, rgba(31, 65, 95, 1) 100%);
        border-radius: 5px;
        box-shadow: 0 0 5px @b, inset 0 1px 0 @w1, inset 1px 0 0 @w1;
        display: flex;

        .sp_icon {
          width: calc(100% / 3);
          height: 100%;
          border-right: 0.1rem solid @b5;
          background-position: center;
          background-repeat: no-repeat;
          box-shadow: inset -1px 0 0 @w1;
          cursor: pointer;
          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          &.sp_icon_view {
            background-image: url(../assets/icons/icon_view.png);
          }
          &.sp_icon_edit {
            background-image: url(../assets/icons/icon_edit.png);
          }
          &.sp_icon_delete {
            background-image: url(../assets/icons/icon_delete.png);
          }
          &:last-child {
            border-right: none;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }

      &.itemFlowTotal {
        font-size: 2rem;
        // font-weight: bold;
        color: cyan;
        cursor: pointer;
      }
      &.itemTaskTotal {
        font-size: 2rem;
        // font-weight: bold;
        color: rgb(255, 204, 0);
        cursor: pointer;
      }
      &.itemTaskTotal2 {
        font-size: 2rem;
        // font-weight: bold;
        color: rgb(0, 255, 68);
        cursor: pointer;
      }
      &.itemTaskTotal3 {
        font-size: 2rem;
        // font-weight: bold;
        color: rgb(197, 143, 255);
        cursor: pointer;
      }

      &.tabelImg {
        width: 180px;
        cursor: pointer;
      }
      &.itemDesc {
        text-align: left;
        width: 400px;
        font-size: 1.4rem;
      }

      &.item_action_td {
        width: 200px;
      }
    }
  }
}

.mainContent {
  height: calc(100% - 1px);
  margin-top: 1px;
  overflow: hidden;
  display: flex;
  .mc_left {
    height: 100%;
    width: 20rem;
    padding: 0.2rem;
    background-color: #000;
    border-right: 1px solid @w3;
    .left_inner {
      height: 100%;
      padding: 1rem;
      background-color: rgba(47, 56, 65, 1);
      .cus_scroll();
    }
    .plistArea {
      li {
        margin-bottom: 1.5rem;
        cursor: pointer;
        .pname {
          font-size: 1.4rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: @b;
          padding: 0.5rem 1rem;
          background: linear-gradient(
            0deg,
            rgba(143, 148, 151, 1) 0%,
            rgba(120, 120, 130, 1) 50%,
            rgba(140, 140, 142, 1) 100%
          );
          border-radius: 5px;
          border: 1px solid @b;
          box-shadow: 0 0 5px @b, inset 1px 1px 0 @w2, inset -1px 0 0 @w1;
          .ext {
            height: 1rem;
            width: 1rem;
            background-image: url(../assets/icons/arrow_right.svg);
            background-repeat: no-repeat;
            background-position: right center;
            background-size: 0.5rem;
          }
        }
        .showFlowList {
          display: none;
        }
        &.active {
          .pname {
            background: linear-gradient(0deg, rgb(49, 107, 158) 0%, rgba(31, 65, 95, 1) 100%);
            color: @w8;
            text-shadow: 0 1px 0 @b;
            .ext {
              background-image: url(../assets/icons/arrow_down.svg);
              background-size: 1rem;
            }
          }
          .showFlowList {
            display: block;
            margin-top: 1rem;
            border-radius: 5px;
            box-shadow: 0 0 5px @b;
            padding: 1rem;
            border: 1px solid @w2;
            background-color: @b3;
            .showsFlow {
              border: 1px solid @b;
              border-bottom: none;
              background-color: #2f3540;
              color: @w6;
              font-size: 1.4rem;
              padding: 0.5rem 1rem;
              box-shadow: inset 0 -1px 0 @w1, inset 1px 0px 0 @w1, inset -1px 0px 0 @w1;
              display: flex;
              align-items: center;

              span {
                margin-left: 1rem;
              }
              &:first-child {
                box-shadow: inset 0 1px 0 @w1, inset 0 -1px 0 @w1, inset 1px 0px 0 @w1,
                  inset -1px 0px 0 @w1;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
              }
              &:last-child {
                border-bottom: 1px solid @b;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
              }
              &.active {
                background: linear-gradient(0deg, rgb(49, 107, 158) 0%, rgba(31, 65, 95, 1) 100%);
              }
            }
          }
        }
      }
    }
  }
  .mc_right {
    height: 100%;
    width: calc(100% - 20rem);
    // background-color: blue;
    &.hide {
      width: 100%;
      height: 100%;
    }
  }
}

.showFname {
  display: flex;
  justify-content: space-between;
  background-image: url(../assets/icons/dot_icon.png);
  background-repeat: no-repeat;
  background-position: 1rem center;
  background-size: 20px;
  padding-left: 4rem;
  .sp_left {
    display: flex;
    align-items: center;
    justify-content: center;
    .sp_name {
      font-size: 1.4rem;
      color: aqua;
    }
    .sp_date {
      margin-left: 0.5rem;
      font-size: 1rem;
    }
  }
  .sp_right {
    width: 50px;
    margin-right: 1rem;
    background: linear-gradient(0deg, rgba(25, 52, 76, 1) 0%, rgba(31, 65, 95, 1) 100%);
    border-radius: 3px;
    box-shadow: 0 0 5px @b, inset 0 1px 0 @w2, inset 1px 0 0 @w2;
    display: flex;
    .sp_icon {
      width: calc(100px / 2);
      border-right: 0.1rem solid @b5;
      box-shadow: inset 1px 0 0 @w1;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      &.sp_icon_view {
        background-image: url(../assets/icons/icon_view.png);
      }
      &.sp_icon_edit {
        background-image: url(../assets/icons/icon_edit.png);
        background-size: 12px;
      }
      &.sp_icon_delete {
        background-image: url(../assets/icons/icon_delete.png);
        background-size: 14px;
      }
      &:last-child {
        border-right: none;
      }
      &:first-child {
        box-shadow: none;
      }
    }
  }
}

.settingSelectorArea {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid @b;
  box-shadow: inset 0 0 10px @w2, inset 0 -1px 0 @w2;
  .ss_left {
    height: 100%;
    .ss_selector {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      li {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0 2rem;
        font-size: 1.4rem;

        border-right: 1px solid @b;
        box-shadow: inset -1px 0 0 @w1;
        &:first-child {
          border-left: 1px solid @b;
        }
        &.active {
          background: linear-gradient(0deg, rgb(49, 107, 158) 0%, rgba(31, 65, 95, 1) 100%);
          box-shadow: inset 0 0 10px @b1, inset 0 -1px 0 @w3, inset -1px 0px 0 @w1,
            inset 1px 0px 0 @w1;
          color: @w;
          text-shadow: 0 1px 0 @b;
        }
      }
    }
  }
  .ss_right {
    height: 100%;
    .subbtn {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 2rem;
      font-size: 1.6rem;
      color: @w;
      text-shadow: 0 1px 0 @b;
      border-left: 1px solid @b;
      border-right: 1px solid @b;
      box-shadow: inset -1px 0 0 @w1;
      background: linear-gradient(0deg, rgb(8, 76, 164) 0%, rgb(3, 25, 71) 100%);
      box-shadow: inset 0 0 10px @b1, inset 0 -1px 0 @w3, inset -1px 0px 0 @w1, inset 1px 0px 0 @w1;
      cursor: pointer;
    }
    .validbtn {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 2rem;
      font-size: 1.6rem;
      color: @w;
      text-shadow: 0 1px 0 @b;
      border-left: 1px solid @b;
      border-right: 1px solid @b;
      box-shadow: inset -1px 0 0 @w1;
      background: linear-gradient(0deg, rgb(29, 30, 30) 0%, rgb(46, 46, 46) 100%);
      box-shadow: inset 0 0 10px @b1, inset 0 -1px 0 @w3, inset -1px 0px 0 @w1, inset 1px 0px 0 @w1;
      cursor: pointer;
    }
  }
  // background-color: #08dbb8;
}

.show {
  display: block;
}
.hide {
  display: none;
}

.runType{
  li{
    list-style: disc;
    list-style-position: inside;
    cursor: pointer;
    color: aqua;
    padding: 0.5rem 1rem;
    &:hover{
      background-color: @w1;
    }
  }
}

.subpage {
  padding: 2rem 3rem;
  overflow: auto;
  height: calc(100vh - 10rem);
  .cus_scroll();
}
.subpageTitle {
  font-size: 2rem;
  color: @w;
}

.showInputLine{
  display: flex;
  align-self: center;
}

.inputError{
  border: 1px solid red;
  padding: 1rem 2rem;
  box-shadow: 0 0 10px red;
  .subpageTitle{
    color: red;
  }
  .iuputError_desc{
    color: red;
    font-size: 2rem;
  }
}
.p_topToolBar {
  height: 4rem;
  // border-top: 2px solid @b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid @b;
  border-left: 1px solid @b;
  box-shadow: inset 0 -1px 0 @w2, 0 1px 0 @w2;
  // background-color: @b;
  background: linear-gradient(180deg, #404040 0%, #505050 56%);
  .p_tools {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    li {
      display: flex;
      align-items: center;
      margin-right: 2rem;
      cursor: pointer;
      .p_tools_text {
        margin-left: 0.5rem;
      }
      .p_tools_changeParams {
        display: flex;
        li {
          margin-right: 0;
          padding: 0.3rem 0.7rem;
          border: 1px solid @b;
          border-right: none;
          box-shadow: inset 1px 1px 0 @w1;
          cursor: pointer;
          &:first-child {
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
          }
          &:last-child {
            border-right: 1px solid @b;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
          }
          &.active {
            background: linear-gradient(180deg, #02a2ff 0%, #002d55 100%);
          }
        }
      }
      &.sepLine {
        width: 1px;
        height: 50%;
        border-left: 1px solid @b5;
        box-shadow: 1px 0 0 @w2;
      }

      &.activeMode {
        color: @w;
        box-shadow: inset 1px 0 0 @w2, inset -1px 0 0 @w1, inset 0 1px 3px @w2, 0 0 3px @b;
        cursor: auto;
        // background-color: #000000;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        background: linear-gradient(0deg, rgb(0, 116, 183) 0%, rgb(1, 49, 107) 100%);
      }
    }
  }
  .p_tools_pageMod {
    height: 100%;
    display: flex;
    align-items: center;
    li {
      margin-right: 0.5rem;
      padding: 0.5rem;
      cursor: pointer;
      &.active {
        background-color: @b;
      }
    }
  }
}

.p_Editor {
  // height: calc(100%);
  // display: flex;
  position: relative;
  z-index: 10;
  .p_editorMain {
    height: 100%;
    width: calc(100% - 26rem);
    &.hides {
      width: 100%;
    }
  }
}

.p_ParmsPanel {
  position: absolute;
  z-index: 1000;
  top: 2px;
  right: 1px;
  width: 32rem;
  // height: 60%;
  background-color: @b;
  padding: 1px 2px;
  box-shadow: inset 1px 1px 0 @w2, inset -1px -1px 0 @w2;
  &.edgePos {
    top: 110px;
    left: 20px;
  }
  .p_ParmsPanel_title {
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(180deg, #0a517b 0%, #083752 100%);
    padding-left: 1rem;
    padding-right: 0.5rem;
    span {
      color: @w;
    }
    &.edgeTitle {
      background: linear-gradient(180deg, #1f7b0a 0%, #08522d 100%);
    }
  }
  .p_ParmsPanel_inner {
    // height: calc(100% - 2rem);
    padding: 3px;
    // background-color: @b;
      margin-bottom: 0.2rem;
      .p_ParmsPanel_ele_title {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border: 1px solid @w1;
        padding: 0.5rem 0.8rem;
        background-color: #000000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          color: aqua;
        }
      }
      .p_ParmsPanel_ele_content {
        padding: 0.8rem;
        background-color: #1e1e1e;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
  .p_ParmsPanel_submitArea {
    height: 3rem;
    margin-top: 0.5rem;
    // background-color: #383838;
    // border-radius: 0.5rem;
  }


.formitemsList {
  .itemTitle {
    color: #02a2ff;
    background-color: @b8;
    text-align: center;
  }
  padding-bottom: 0.8rem;
  margin-bottom: 0.5rem;
  border: 1px solid @b5;
  background-color: @b5;
  box-shadow: 0 1px 0 @w1;
}

.p_elementsbar {
  height: 14rem;
  .p_elementsbar_top {
    display: flex;
    justify-content: space-between;
    height: 3rem;
    background: linear-gradient(180deg, rgb(70, 69, 69) 0%, rgb(33, 33, 33) 56%);
    border-top: 2px solid @b;
    border-bottom: 2px solid @b;

    box-shadow: inset 0 1px 0 @w2, 0 1px 0 @w1;
    .p_ele_close {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      font-size: 1.8rem;
      cursor: pointer;
    }
    .p_ele_cate {
      display: flex;
      height: 100%;
      box-shadow: 1px 0 0 @w2;
      li {
        height: 100%;
        font-size: 1.4rem;
        padding: 0 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-right: 1rem;
        border-right: 2px solid @b8;
        box-shadow: inset 1px 0 0 @w2;
        cursor: pointer;
        &:hover {
          background: linear-gradient(180deg, rgb(33, 33, 33) 0%, rgb(70, 69, 69) 56%);
        }
        &.active {
          background: linear-gradient(0deg, rgb(49, 107, 158) 0%, rgba(31, 65, 95, 1) 100%);
        }
      }
    }
  }
  .p_cate_children {
    height: calc(100% - 3rem);
    display: flex;
    li {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 2rem;
    }
  }
}

.reUl {
  display: flex;
  height: 7rem;
  align-items: center;
  justify-content: center;
  background-color: @b6;
  li {
    margin-left: 8rem;
    text-align: center;
    &:first-child {
      margin-left: 0;
    }
    p {
      span{
        color: @w8;
        font-size: 20px;
      }
      &:last-child {
        font-size: 2rem;
        font-weight: bold;
        color: #02a2ff;
      }
    }
  }
}

.reUl2{
  // display: flex;
  // height: 7rem;
  // align-items: center;
  // border-radius: 10px;
  // justify-content: center;
  width: 100%;
  // background-color: @b6;
  padding: 0 3rem;
  li {
    // margin-left: 8rem;
    display: flex;
    height: 8rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid @w1;
    box-shadow: inset 0 -1px 0 @b5;
    &:last-child {
      border-bottom:none;
      box-shadow: none;
    }
    p {
      span{
        color: @w8;
        font-size: 20px;
        &.unit{
          font-size: 14px;
          color:@w6
        }
      }
      &:last-child {
        font-size: 2rem;
        font-weight: bold;
        color: #02a2ff;
      }
    }
  }
}

.showProKey {
  padding-right: 2rem;
  li {
    margin-bottom: 1rem;
    border: 1px solid @w1;
    padding: 0.5rem 1rem;
    color: @w6;
    font-size: 1.6rem;
    cursor: pointer;
    &:hover {
      background-color: @w1;
    }
    &.active {
      box-shadow: inset 1px 0 0 @w2, inset -1px 0 0 @w1, inset 0 1px 3px @w2, 0 0 10px @b;
      border-radius: 5px;
      border: 1px solid @b;
      // font-size: 1.2rem;
      padding: 0.5rem 1rem;
      color: @w;
      // display: inline-block;
      box-shadow: inset 1px 0 0 @w2, inset -1px 0 0 @w1, inset 0 1px 3px @w2, 0 0 3px @b;
      background: linear-gradient(0deg, rgb(0, 116, 183) 0%, rgb(1, 49, 107) 100%);
    }
  }
}
.r_dsl {
  padding-right: 3rem;
  li {
    border-bottom: 1px solid @w1;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    .r_dsl_title {
      width: 120px;
      color: @w8;
      &::after {
        content: "：";
      }
    }
    .r_dsl_value {
      color: aqua;
      font-size: 18px;
    }
    .r_dsl_unit {
      margin-left: 10px;
      color: @w6;
    }
  }
}

.rtableA {
  table {
    width: 100%;
    border-top: 1px solid @w1;
    border-left: 1px solid @w1;
    th {
      padding: 10px 10px;
      border-right: 1px solid @w1;
      border-bottom: 1px solid @w1;
      color: #08dbb8;
      font-size: 16px;
      background-color: @b7;
      font-weight: normal;
      &.unit {
        font-weight: 100;
        color: @w6;
        font-size: 14px;
      }
    }
    td {
      text-align: center;
      font-size: 16px;
      border-right: 1px solid @w1;
      border-bottom: 1px solid @w1;
      padding: 8px 8px;
      color: @w;
      &:first-child {
        color: @orange;
        font-weight: bold;
      }
    }
    tr {
        &:hover {
          background-color: #022d49;
        }
        // cursor: pointer;
        &.active {
          background-color: #022d49;
        }
    }
  }
}
.showRtable{
  height: calc(100vh - 341px);
  .cus_scroll()
}

.ant-pagination {
  li {
    &.ant-pagination-item {
      a {
        color: aqua;
        &:hover {
          background-color: @w1;
          border-radius: 5px;
        }
      }

      &.ant-pagination-item-active {
        background-color: @b3;
        a {
          color: yellow;
        }
      }
    }

    &.ant-pagination-prev {
      .anticon-left {
        color: @w5;
      }
    }

    &.ant-pagination-next {
      .anticon-right {
        color: @w5;
      }
    }
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: @w5;
  }

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: @w5;
  }
}

.pro_flow_titile {
  position: absolute;
  left: 2rem;
  top: 2rem;
  font-size: 1.8rem;
  background-color: @b5;
  padding: 0.8rem 4rem 0.8rem 1.5rem;
  z-index: 10;
  border: 1px solid @w1;
  box-shadow: 1px 1px 0 @b, -1px -1px 0 @b, 0 0 10px @b;
  p {
    padding: 0 0.5rem;
    color: aqua;
  }
  .createdate {
    font-size: 1.2rem;
    border-top: 1px solid @w1;
    padding-top: 0.5rem;
    margin-top: 0.5rem;
    color: @w6;
    text-align: center;
  }
  .titleClose {
    position: absolute;
    right: 1rem;
    cursor: pointer;
  }
}

.ant-divider {
  border: none;
  border-bottom: 1px solid rgba(0, 255, 255, 0.15);
  box-shadow: 0 1px 0 @b;
}

.resultTab {
  display: flex;
  margin-bottom: 1rem;
  li {
    box-shadow: inset 1px 0 0 @w2, inset -1px 0 0 @w1, inset 0 1px 3px @w2, 0 0 5px @b;
    border-radius: 5px;
    border: 1px solid @b;
    padding: 0.8rem 2.5rem;
    margin-right: 2rem;
    text-align: center;
    font-size: 1.8rem;
    cursor: pointer;
    color: @w8;
    text-shadow: 0 1px 0 @b;
    background: linear-gradient(0deg, rgb(119, 119, 119) 0%, rgb(37, 37, 37) 100%);
    &:hover {
      background: linear-gradient(0deg, rgb(37, 37, 37) 0%, rgb(164, 164, 164) 100%);
    }
    &.active {
      background: linear-gradient(0deg, rgb(0, 116, 183) 0%, rgb(1, 49, 107) 100%);
      color: @w;
    }
  }
}

.ant-select-dropdown {
  border-radius: 0;
  background-color: @b;
  border: 1px solid @w3;
  .ant-select-item {
    border-radius: 0;
    &.ant-select-item-option {
      &.ant-select-item-option-selected {
        background: linear-gradient(0deg, rgb(0, 116, 183) 0%, rgb(1, 49, 107) 100%);
        box-shadow: inset 1px 0 0 @w2, inset -1px 0 0 @w1, inset 0 1px 3px @w2;
      }
      &.ant-select-item-option-active {
        background: linear-gradient(0deg, rgb(0, 116, 183) 0%, rgb(1, 49, 107) 100%);
        box-shadow: inset 1px 0 0 @w2, inset -1px 0 0 @w1, inset 0 1px 3px @w2;
        &.ant-select-item-option-selected {
          background: linear-gradient(0deg, rgb(0, 116, 183) 0%, rgb(1, 49, 107) 100%);
          box-shadow: inset 1px 0 0 @w2, inset -1px 0 0 @w1, inset 0 1px 3px @w2;
        }
      }
    }
  }
}

.ant-select {
  .ant-select-arrow {
    color:@w6;
  }

  .ant-select-selector input{
    &:focus{
      border: none;
      box-shadow: none;
    }
  }
  .ant-select-selection-placeholder {
    color: @w3;
  }
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: @w;
}

.ant-drawer{
  .ant-drawer-content{
    border-left:1px solid @w1;
    border-right:1px solid @w1;
    box-shadow: inset 1px 0 0 @b,inset -1px 0 0 @b;
  }
  .ant-drawer-header{
    border-bottom: 1px solid @w2;
    border-top: 1px solid @b;
    box-shadow: inset 0 -1px 0 @b6,inset 0 1px 0 @w2,inset 1px 0 0 @b;
    padding: 1rem 1.5rem;
    background: linear-gradient(0deg, rgb(51, 51, 51) 0%, rgb(25, 25, 25) 100%);
  }
  .ant-drawer-body{
    padding: 1rem 1.5rem;
  }
}

.ant-tabs-left >.ant-tabs-content-holder >.ant-tabs-content>.ant-tabs-tabpane{
  padding-left: 0;
}

.ant-tabs-left >.ant-tabs-content-holder{
  border-left: 1px solid @w1;
  box-shadow: -1px 0 0 @b;
}

.showRPage {
  padding: 2rem 2rem;
}

.showInputNodes{
  display: flex;
  color: @w8;
  .nodeimg{
    text-align: center;
    padding: 2rem 4rem;
  }
  .showInputNodesParams{
    li{
      border-bottom: 1px solid @w1;
      padding: 0.5rem 1rem;
    }
  }
}
.showInputNodesParams_line{
  display: flex;
  align-items: center;
  .sl_name{
    font-size: 1.6rem;
    width: 150px;
    &::after{
      content: '：';
    }
  }
  .sl_value{
    font-size: 1.8rem;
    color: aqua;
  }
  .sl_unit{
    margin-left: 0.5rem;
    color: @w5;

  }
}

.showInputNodeEdge{
  margin-left: 2rem;
  .showInputNodeEdgeList{
    border: 1px solid @w1;
    background-color: @b2;
    padding:1rem 2rem;
    li{
      padding: 0.5rem 1.5rem;
      border:1px solid @w1;
      margin-bottom: 0.5rem;
      font-size: 1.6rem;
      color:#08dbb8;
      &.showInputNodeEdgeList_title{
        font-size: 1.8rem;
        color:@w;
        font-weight: bold;
        padding: 0;
        border: none;
      }

    }
  }
}

.showInputBlock{
  border:1px solid @w1;
  .showInputBlock_title{
    padding: 1rem 2rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid @w1;
    box-shadow: 0 1px 0 @b;
    background-color: @b2;
  }
  .showInputBlock_body{
    padding: 1rem;
  }
}

.ant-tabs-card{
  .ant-tabs-nav{
    .ant-tabs-tab{
      border: 1px solid @w1;
      box-shadow: inset 0 1px 0 @b,inset 1px 0 0 @b;
      // background-color: @b3;
    }
    .ant-tabs-tab-active{
      background: linear-gradient(0deg, rgb(0, 116, 183) 0%, rgb(1, 49, 107) 100%);
    }
  }
  &.ant-tabs-left{
    .ant-tabs-nav{
      .ant-tabs-tab{
        border-radius: 0;
      }
      .ant-tabs-tab-active{
        border-right:1px solid aqua
      }
    }
  }
} 


.ant-tabs{
  .ant-tabs-tab{
    .ant-tabs-tab-btn{
      font-size: 1.6rem;
    }
    &.ant-tabs-tab-active{
      .ant-tabs-tab-btn{
        color: @w;
      }
    }
  }
} 

.resultShowArea{
  display: flex;
  height: 100%;
  padding: 0;
  overflow: hidden;
  .rsa_left{
    width: 360px;
    height: 100%;
  }
  .rsa_right{
    width: calc(100% - 360px);
    padding-left: 3rem;
  }
}

.showResultInputs{
  height: calc(100vh - 210px);
  .cus_scroll()
}

.showInputResultBlock{
  border:1px solid @b;
  border-radius: 5px;
  box-shadow: inset -1px 0 0 @w2,  inset 1px 0 0 @w2, inset 0 1px 0 @w2,0 1px 0 @w2,0 0 5px @b5;

  // padding: 1rem;
  .sirb_title{
    padding: 0.5rem 1rem;
    font-size: 1.6rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: linear-gradient(0deg, rgb(0, 116, 183) 0%, rgb(1, 49, 107) 100%);
    box-shadow: inset 1px 0 0 @w2, inset 0 1px 0 @w2;
    color: @w;
    border-bottom: 1px solid @w1;
    box-shadow: 0 1px 0 @b;
  }
  .sirb_content{
    padding: 1rem;
  }
}

.showInputEdgeList{
  li{
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    border-bottom: 1px solid @w1;
    padding: 0.5rem 1rem;
    .v_label{
      width: 5em;
      color: @w;
    }
    .v_value{
      color: #08dbb8;
      font-weight: bold;
    }
    .v_unit{
      margin-left: 0.5rem;
      color: @w5;
      font-size: 1.2rem;
    }
  }
}

.showEdgeComponents,.sirb_content{
  width: 100%;
  table {
    width: 100%;
    border-top: 1px solid @b;
    border-left: 1px solid @b;
    border-radius: 5px;
    th {
      font-size: 1.4rem;
      padding: 0.8rem;
      background: linear-gradient(0deg, rgb(0, 93, 174) 0%, rgb(2, 44, 78) 100%);
      box-shadow: inset 1px 0 0 @w2, inset 0 1px 0 @w2;
      border-right: 1px solid @b;
      text-align: center;
      color: @w;
      font-weight: normal;
      text-shadow: 0 2px 0 @b;
      &:first-child {
        border-top-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
      }
      .unit{
        font-size: 12px;
        color: @w6;
      }
      &.tempUse{
        background: #005CAD;
        border-radius: 0;
        box-shadow: inset 1px 0 0 @w2, inset 0 1px 0 @b,inset 0 2px 0 @w2;;
      }
    }
    td {
      text-align: center;
      padding: 1rem;
      border-bottom: 1px solid @b;
      border-right: 1px solid @b;
      background-color: @b3;
      font-size: 1.4rem;
      box-shadow: inset -1px 0 0 @w1, inset 1px 0 0 @w1, inset 0 -1px 0 @w1;
    }
  }
}
// .ant-tabs-card >.ant-tabs-nav .ant-tabs-tab

@media (min-width: 1024px) {
  .Composer {
    height: auto;
  }
}
