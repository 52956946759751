.node {
  position:relative;
  user-select: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid transparent;
  /* text-align: center; */
  /* width: 50px;
  height: 250px; */
  &:hover{
    border: 1px solid rgb(255, 238, 0);
  }
  &:active{
    border: 1px solid red;
  }
  &:selected{
    border: 1px solid red;
  }
}

.node2 {
  position: relative;
  user-select: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: 30px;
}

.menu {
  display: none;
  position: absolute;
  background: #000;
  border: 1px solid rgba(255,255,255,0.3);
  /* box-shadow: 0 0 10px #000; */
  border-radius: 5px;
  list-style: none;
  /* padding: 4px 0; */
  margin: 0;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  min-width: 150px;
  z-index: 1000;
  overflow: hidden;
  transform-origin: top left;
}

.menuItem {
  padding: 1rem 2rem;
  cursor: pointer;
  font-size: 20px;
  border-bottom: 1px solid rgba(255,255,255,0.3);
  /* border-radius: 5px; */
}

.menuItem:hover {
  background-color: rgba(255,255,255,0.2);
}

/* .divider {
  height: 1px;
  background-color: #ddd;
  margin: 4px 0;
} */