@import './var.less';

.light {


  background-color: @w;
  .activeRoute {
    background-color: @w1;
    color: @w;
    font-weight: 700;
  }
  .activeSubRoute {
    background-color: @b1;
    color: @b;
    font-weight: 700;
  }
  .unit,.unit2{
    color: @b5;
  }

  .pageTopBar{
    background-color: #242629;
  }

  // .ant-tabs-top >.ant-tabs-nav{
  //   margin-bottom: 0;
  // }




}
